import React from "react"
import { Button, Grid } from "@material-ui/core"
import { useSelector } from "react-redux"
import { TripStatus } from "../../../../constants/types"
import i18next from "i18next"

export const ResumeTripActions = ({ trip, onRejectClick, onAcceptClick, onRepeatTrip, handleOnClickPlan }) => {

    const tripReducer = useSelector((state) => state.tripReducer)
    const inMultipleTripScheduleReturn = useSelector((state) => state.multipleTripReducer.areInReturnProcess)
    const executeMultipleTrips = useSelector((state) => state.multipleTripReducer.executeMultipleTrips)


    return (
        <>
            {(!executeMultipleTrips || inMultipleTripScheduleReturn) && (
                <Grid
                    container={inMultipleTripScheduleReturn ?? undefined}
                    item
                    md={inMultipleTripScheduleReturn ? 12 : 3}
                    sm={6}
                    xs={12}
                    justify={inMultipleTripScheduleReturn ? 'flex-end' : undefined}>
                    <AcceptRejectButtons
                        trip={trip}
                        onAcceptClick={onAcceptClick}
                        onRejectClick={onRejectClick}
                        inMultipleTripScheduleReturn={inMultipleTripScheduleReturn}
                        tripPending={tripReducer.pending}
                    />
                    <RepeatScheduleButtons
                        trip={trip}
                        handleOnClickPlan={handleOnClickPlan}
                        onRepeatTrip={onRepeatTrip}
                        inMultipleTripScheduleReturn={inMultipleTripScheduleReturn}
                        tripPending={tripReducer.pending}
                    />
                </Grid>
            )}
        </>
    )
}

const RepeatScheduleButtons = ({ trip, onRepeatTrip, handleOnClickPlan, inMultipleTripScheduleReturn, tripPending }) => {
    return (
        <>
            {[
                TripStatus.AcceptedByCustomerService,
                TripStatus.AcceptedByUser,
                TripStatus.AcceptedByIVR,
                TripStatus.AcceptedByThirdParty,
                TripStatus.AcceptedByDriver,
                TripStatus.DriverAssignedByOperator,
                TripStatus.DriverAssignedAfterCompaction
            ].includes(trip.status) && !inMultipleTripScheduleReturn ? (
                <>
                    {!trip.isReturn && !trip.hasReturn &&
                        <Grid item xl={6} md={10} sm={11} xs={12} style={{ padding: 10 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="medium"
                                disabled={tripPending}
                                onClick={() => onRepeatTrip(trip)}
                            >
                                {i18next.t('form.resumeService.repeat')}
                            </Button>
                        </Grid>
                    }
                    <Grid item xl={6} md={10} sm={11} xs={12} style={{ padding: 10 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="medium"
                            disabled={tripPending}
                            onClick={() => handleOnClickPlan(trip)}
                        >
                            {trip.isReturn ? i18next.t('form.resumeService.programTripOutboundAndReturn') : i18next.t('form.resumeService.programTrip')}
                        </Button>
                    </Grid>
                </>
            ) : null}
        </>
    )
}


const AcceptRejectButtons = ({ trip, onRejectClick, onAcceptClick, inMultipleTripScheduleReturn, tripPending }) => {
    return (
        <>
            {trip.status == TripStatus.Available ? (
                <React.Fragment>
                    <Grid item xl={inMultipleTripScheduleReturn ? 3 : 6} md={inMultipleTripScheduleReturn ? 6 : 10} sm={11} xs={12} style={{ padding: 10 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="medium"
                            disabled={tripPending}
                            onClick={() => onRejectClick(trip.tripId)}
                        >
                            {i18next.t('form.resumeService.reject')}
                        </Button>
                    </Grid>
                    <Grid item xl={inMultipleTripScheduleReturn ? 3 : 6} md={inMultipleTripScheduleReturn ? 6 : 10} sm={11} xs={12} style={{ padding: 10 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="medium"
                            disabled={tripPending}
                            onClick={() => onAcceptClick(trip.tripId)}
                        >
                            {i18next.t('form.resumeService.accept')}
                        </Button>
                    </Grid>
                </React.Fragment>
            ) : null}
        </>
    )
}