import { authHeader } from '../helpers/authHeader';
import stringConstants from '../constants/strings';
import i18next from 'i18next';


export const tripsService = {
    customerCanPerformTrip,
    requestTrip,
    getTripServices,
    setTripToAcceptedByUser,
    setTripToCancelledByUser,
    setTripToRejectedByUser,
    getDestinationZones,
    getWeekDays,
    getTripStatuses,
    getById,
    processMultipleTrip,
    processMultipleTripRetry,
    getTripsFromMultipleRequest,
    processMultipleTripReturn,
    rejectTrip,
    cancelTrip,
    processMultipleTripOutboundAndReturn,
};

async function requestTrip(trip, serviceId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader(),
        },
        body: JSON.stringify({ ...trip, isWebCustomersRequest: true })
    };
    const URL = `${stringConstants.URL_ApiGateway}/api/Services/v1/Trips/RequestTrip?serviceId=${serviceId}`
    return await fetch(URL, requestOptions)
        .then(handleResponse)
        .then(
            response => {
                return response;
            }
        )
}

async function getTripServices(trip, customerId, noRestriction = false) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader(),
        },
        body: JSON.stringify(trip)
    };
    const lang = i18next.language
    return await fetch(stringConstants.URL_ApiGateway +
        '/api/Services/v1/trips/getTripServices?customerId=' +
        customerId +
        '&noRestriction=' +
        noRestriction +
        '&lang=' +
        lang,
        requestOptions)
        .then(handleResponse)

}

function getTripStatuses() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    }
    const lang = i18next.language
    return fetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/tripStatus/TripStatuses?lang=' +
        lang,
        requestOptions,
    ).then(handleResponse)
}

function customerCanPerformTrip(pickupStartTime, customerId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    };
    return fetch(stringConstants.URL_ApiGateway + '/api/services/v1/Trips/CustomerCanPerformTrip?pickupStartTime=' + pickupStartTime + '&customerId=' + customerId, requestOptions).then(handleResponse);
}

async function setTripToCancelledByUser(tripId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/TripStatus/SetTripToCancelledByUser?id=' + tripId, requestOptions).then(handleResponse)
        .then(
            response => {
                //console.log('resposta setTripToCancelledByUser => ', response.status);
                return response//.status
            }
        );
}

async function setTripToAcceptedByUser(tripId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/TripStatus/SetTripToAcceptedByUser?id=' + tripId, requestOptions).then(handleResponse)
        .then(
            response => {
                //console.log('resposta setTripToAcceptedByUser => ', response.status);
                return response//.status
            }
        );
}

async function setTripToRejectedByUser(tripId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/TripStatus/SetTripToRejectedByUser?id=' + tripId, requestOptions)
        .then(
            response => {
                //console.log('resposta setTripToRejectedByUser => ', response.status);
                return response.status
            }
        );
}

async function getDestinationZones(zoneId, lat, lon) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/serviceZones/GetDestinationZones?zoneId=' + zoneId, requestOptions)
        .then(
            handleResponse
        );
}

async function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    }
    return await fetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/trips/GetTripById?tripId=' +
        id,
        requestOptions,
    ).then(handleResponse)
}


async function processMultipleTrip(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        },
        body: JSON.stringify(body),
    }
    return fetch(
        stringConstants.URL_ApiGateway + `/services/v1/trips/processMultipleTrip`,
        requestOptions,
    ).then(handleResponse)
}

async function processMultipleTripRetry(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        },
        body: JSON.stringify(body),
    }
    return fetch(
        stringConstants.URL_ApiGateway + `/services/v1/trips/processMultipleTripRetry`,
        requestOptions,
    ).then(handleResponse)
}

async function getWeekDays() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    const lang = i18next.language
    return await fetch(stringConstants.URL_ApiGateway + '/services/v1/services/weekDays?lang=' + lang, requestOptions)
        .then(
            handleResponse
        );
}

async function getTripsFromMultipleRequest(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    }
    return fetch(
        stringConstants.URL_ApiGateway +
        `/services/v1/trips/getTripsFromMultipleRequest?tripId=${id}`,
        requestOptions,
    ).then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || text || response.status;
            return Promise.reject(error);
        }
        return data;
    });
}


async function rejectTrip(id) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    }
    return fetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/TripStatus/SetTripToRejectedByCustomerService?id=' +
        id,
        requestOptions,
    ).then(handleResponse)
}

async function processMultipleTripReturn(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        },
        body: JSON.stringify(body),
    }
    return fetch(
        stringConstants.URL_ApiGateway + `/services/v1/trips/processMultipleTripReturn`,
        requestOptions,
    ).then(handleResponse)
}

async function processMultipleTripOutboundAndReturn(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        },
        body: JSON.stringify(body),
    }
    return fetch(
        stringConstants.URL_ApiGateway + `/services/v1/Trips/ProcessMultipleTripOutboundAndReturn`,
        requestOptions,
    ).then(handleResponse)
}

async function cancelTrip(id) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        },
    }
    return fetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/TripStatus/SetTripToCancelledByCustomerService?id=' +
        id,
        requestOptions,
    ).then(handleResponse)
}
