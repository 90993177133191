
import { existRefreshToken, userService } from '../../services/userService';
import { tripConstants } from '../../constants/trip_constants';
import { userActions } from './user_actions';
import { alertActions } from './alert_actions';
import { history } from '../../helpers/history';
import { zoneStopTypes } from '../../constants/zoneStopTypes';
import store from '../store';
import { tripsService } from '../../services/tripsService';
import moment from 'moment';
import i18next from 'i18next';
import { REGULAR_SERVICES_TYPES, SERVICES_TYPE } from '../../constants/types';
import { regularLine_actions } from './regularLine_actions';
import { factory } from '../../helpers/factory';
import { getDropOffLocationAddress, getPickUpLocationAddress } from '../../helpers/tools';
import { multipleTripsActions } from './multipleTrips_action';
export const MY_CUSTOM_ADDRESS = "myCustomAddress";
export const tripActions = {
    requestTrip,
    getTripsFromCustomer,
    setTrip,
    setTripToCancelledByUser,
    setTripToRejectedByUser,
    setTripToAcceptedByUser,
    getTripServices,
    selectServiceClose,
    setStops,
    closeModalRequestTrip,
    openModalRequestTrip,
    selectedMyCustomAddress,
    selectedStop,
    clearOrigin,
    resetData,
    repeatTrip,
    cleanRepeatTrip,
    cleanTrip,
    setModifyTripData,
    modifyTrip,
    resetModifyData,
    acceptModifyTrip,
    getWeekDays,
    openModalExpeditions,
    closeModalExpeditions,
    getTripStatuses,
    getById,
    clearAllMarkers,
    rejectTrip,
    closeReturnTripModal,
    //getTripDetails
}


function clearAllMarkers() {
    return dispatch => {
        dispatch(request())
    }

    function request(index) {
        return { type: tripConstants.CLEAR_ALL_MARKER }
    }
}

function getTripServices(trip, customerId, noRestriction = false, isRetry = false) {
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        userService.canPerformTripFromProfileData(customerId).then(
            result => {
                if (result === true) {
                    tripsService.customerCanPerformTrip(
                        moment(trip.requestPickUpStartTime).toISOString(),
                        customerId,
                        trip.relatedCustomer?.id ?? '',
                    ).then(
                        result => {
                            if (result.canPerformTrip == true) {
                                /**START ORIGINAL GETTRIPSERVICES */
                                tripsService.getTripServices(trip, customerId, noRestriction).then(
                                    services => {
                                        let dataTripRequest = null;
                                        let serviceName = null;
                                        if (services.length > 0) {
                                            dataTripRequest = {
                                                ...trip,
                                                serviceName
                                            }
                                            if (services.length == 1) {
                                                //nomes un servei
                                                serviceName = services[0].name;
                                                dataTripRequest = {
                                                    ...trip,
                                                    serviceName
                                                }

                                                // el servicio no es linea regular
                                                dispatch(success(services, dataTripRequest, false, services[0]))
                                                if (!REGULAR_SERVICES_TYPES.includes(services[0].serviceType)) {
                                                    if (services[0].originZone.zoneType == zoneStopTypes.WithStops ||
                                                        services[0].originZone.zoneType == zoneStopTypes.MixedOriginOrDestination ||
                                                        services[0].originZone.zoneType == zoneStopTypes.MixedOriginAndDestination) {
                                                        // MOSTRAR PARADES ORIGEN AL MAPA
                                                        // HEM DE CRIDAR LA FUNCIÓ PER FILTRAR LES PARADES QUE S'HAN DE MOSTRAR
                                                        dispatch(setStops(services[0], 'origin', services[0].originZone.zoneStops, dataTripRequest))
                                                    } else if (services[0].destinationZone.zoneType == zoneStopTypes.WithStops ||
                                                        services[0].destinationZone.zoneType == zoneStopTypes.MixedOriginOrDestination ||
                                                        services[0].destinationZone.zoneType == zoneStopTypes.MixedOriginAndDestination) {
                                                        // MOSTRAR PARADES DESTÍ AL MAPA
                                                        // HEM DE CRIDAR LA FUNCIÓ PER FILTRAR LES PARADES QUE S'HAN DE MOSTRAR
                                                        dispatch(setStops(services[0], 'destination', services[0].destinationZone.zoneStops, dataTripRequest))
                                                    } else {
                                                        dispatch(requestTrip(trip, services[0].id))
                                                    }
                                                } else {
                                                    // el servicio es linea regular
                                                    let origins = []
                                                    if (services[0].serviceType == SERVICES_TYPE.regularService) {
                                                        services[0].serviceLine.outbound.zoneStops.forEach(function (element, i) {
                                                            element.outbound = true
                                                            element.outboundIndex = i
                                                            element.allowOppositeDirections = services[0].serviceLine.outbound.allowOppositeDirections
                                                            origins.push(element)
                                                        });
                                                        services[0].serviceLine.return.zoneStops.forEach(function (element, i) {
                                                            element.return = true
                                                            element.returnIndex = i
                                                            element.allowOppositeDirections = services[0].serviceLine.return.allowOppositeDirections
                                                            origins.push(element)
                                                        });
                                                    } else {
                                                        services[0].regularLines.forEach(function (line) {
                                                            line.outbound.zoneStops.forEach(function (stop) {
                                                                stop.outbound = true
                                                                if (!origins.find(item => item.id == stop.id))
                                                                    origins.push(stop)
                                                            })
                                                        })
                                                    }
                                                    dispatch(setStops(services[0], 'origin', origins, dataTripRequest))
                                                }

                                            } else {
                                                //més d'un servei disponible length > 1 (mostrar modal amb opcions)
                                                //console.log("Hay más de un servicio en la zona!")
                                                dispatch(success(services, dataTripRequest, true, null))
                                                //mostrem els serveis
                                            }
                                        }
                                    },
                                    error => {
                                        let detailError = i18next.t('services.trips.errorOccurs')//"Se ha producido un error";
                                        let status = '';
                                        try {
                                            let parsedError = JSON.parse(error);
                                            detailError = parsedError.detail;
                                            status = parsedError.status;
                                        } catch (e) {
                                            detailError = i18next.t('services.trips.errorOccurs')//'Se ha producido un error';
                                        }
                                        if (status == 500) {
                                            if (detailError.includes('TripNotProcessed')) {
                                                detailError = i18next.t('services.trips.requestTrip.TripNotProcessed')//'El viaje no se ha podido procesar';
                                                dispatch(failure(detailError));
                                            }
                                        }
                                        if (error === 401) {
                                            dispatch(userActions.refreshToken());
                                            setTimeout(() => {
                                                dispatch(getTripServices(trip, customerId, noRestriction))
                                            }, 2000);
                                        }

                                        try {
                                            let parsedError = JSON.parse(error)
                                            detailError = parsedError.detail
                                            switch (true) {
                                                default:
                                                    parsedError.map(itemError => dispatch(
                                                        alertActions.error(itemError.detail)
                                                    ))
                                            }
                                            dispatch(failure(detailError))
                                        } catch (e) {
                                            detailError = i18next.t(
                                                'services.trips.getServices.TripNotProcessed',
                                            )
                                        }

                                    }
                                )
                                /**END ORIGINAL GETTRIPSERVICES */
                            } else {
                                dispatch(failure(i18next.t('services.trips.canPerformTripError') + moment(result.nextAvailableTime).local().format('HH:mm DD-MM-YYYY')));
                                dispatch(alertActions.error(i18next.t('services.trips.canPerformTripError') + moment(result.nextAvailableTime).local().format('HH:mm DD-MM-YYYY')));

                            }
                        },
                        error => {
                            if (error === 401) {
                                dispatch(userActions.refreshToken());
                                setTimeout(() => {
                                    dispatch(failure(error));
                                    getTripServices(trip, customerId, noRestriction)
                                }, 2000);
                            }
                            dispatch(failure("error in customerCanPerformTrip => " + error));
                        }
                    )
                }
            },
            error => {
                let detailError = "";
                try {
                    let parsedError = JSON.parse(error);
                    detailError = parsedError.detail;
                    if (detailError.includes('RequiredFieldsMissing')) {
                        dispatch(failure(detailError));
                        dispatch(alertActions.error(i18next.t('services.trips.canPerformTripFromProfileData')));
                    }
                    if (detailError.includes('InvalidAge')) {
                        dispatch(failure(detailError));
                        dispatch(alertActions.error(i18next.t('services.trips.invalidAge')));
                    }
                    if (detailError.includes('InvalidDocument')) {
                        dispatch(failure(detailError));
                        dispatch(alertActions.error(i18next.t('services.trips.invalidDocument')));
                    }
                    if (detailError.includes('DocumentCodeAlreadyExists')) {
                        dispatch(failure(detailError));
                        dispatch(alertActions.error(i18next.t('services.customers.repeatDoc')));
                    }
                    if (detailError == "CustomerDisabled") {
                        dispatch(userActions.logout())
                        dispatch(alertActions.error(i18next.t('services.customers.isNotActive')));
                    }
                } catch (e) {
                    //console.log("error in catch => ", e)
                    detailError = e;
                    dispatch(failure(detailError));
                    dispatch(userActions.refreshToken());
                    setTimeout(() => {
                        dispatch(failure(error));
                        getTripServices(trip, customerId, noRestriction)
                    }, 2000);
                    dispatch(alertActions.error(i18next.t('services.trips.errorOccursRepeatAgain')))
                }
            }
        )
    }
    function request() {
        return { type: tripConstants.GET_SERVICES_FROM_TRIP_REQUEST }
    }
    function success(data, dataTripRequest, modalSelectedService, selectedService) {
        return { type: tripConstants.GET_SERVICES_FROM_TRIP_SUCCESS, data, dataTripRequest, modalSelectedService, selectedService }
    }
    function failure(error) {
        return { type: tripConstants.GET_SERVICES_FROM_TRIP_FAILURE, error }
    }
}

function requestTrip(trip, serviceId) {
    return dispatch => {
        dispatch(request());
        tripsService.requestTrip(trip, serviceId).then(
            response => {
                //console.log("response => ", response);
                dispatch(success(response))
                dispatch(alertActions.success(i18next.t('services.trips.requestTrip.acceptedRequest')))
                const params = new URLSearchParams({ id: response.tripId });
                history.replace({ pathname: '/resumeTrip', search: params.toString() });
            },
            error => {
                let detailError = i18next.t('services.trips.errorOccurs')//"Se ha producido un error";
                let status = '';
                let parsedError;
                try {
                    parsedError = JSON.parse(error);
                    detailError = parsedError.detail;
                    status = parsedError.status;
                    //console.log("detailError => ", detailError);
                    //console.log("status => ", status);
                    //console.log("error => ", error);
                } catch (e) {
                    detailError = i18next.t('services.trips.errorOccurs')//'Se ha producido un error';
                }
                if (status == 500) {
                    if (detailError.includes('TripNotProcessed')) {
                        detailError = i18next.t('services.trips.requestTrip.TripNotProcessed')//'El viaje no se ha podido procesar';
                        dispatch(failure(detailError));
                    }
                }
                if (error === 401) {
                    //console.log("error 401")
                    dispatch(userActions.refreshToken());
                    setTimeout(() => {
                        //dispatch(requestTrip())
                        dispatch(failure(error));
                        dispatch(alertActions.error(i18next.t('services.trips.errorRepeatAgain')));
                    }, 2000);
                }
                if (status === 400) {
                    if (detailError.includes('InsufficientAnticipationRequestTime')) {
                        detailError = i18next.t('services.trips.requestTrip.InsufficientAnticipationRequestTime')
                    }
                    if (detailError.includes('InvalidParameters')) {
                        detailError = i18next.t('services.trips.requestTrip.InvalidParameters')//'Parámetros introducidos no válidos';
                    }
                    if (detailError.includes('ServiceZonesNotFound')) {
                        detailError = i18next.t('services.trips.requestTrip.ServiceZonesNotFound')//'No se ha encontrado ninguna zona en el sistema dada la posición de origen o la posición de destino';
                    }
                    if (detailError.includes('ServicesNotFound')) {
                        detailError = i18next.t('services.trips.requestTrip.ServicesNotFound')//'No se han encontrado servicios activos';
                    }
                    if (detailError.includes('VehiclesNotFound')) {
                        if (detailError.includes('No vehicles available for given trip')) {
                            detailError = i18next.t('services.trips.requestTrip.VehiclesNotFound')//'No hay vehículos disponibles en el servicio';
                        }
                        if (detailError.includes('Only Freelance vehicles available in service')) {
                            detailError = i18next.t('services.trips.requestTrip.OnlyFreelanceVehiclesAvailable')//'Solamente hay disponibles vehículos de tipología Freelance';
                        }
                        if (detailError.includes('Failed to obtain Vehicles data')) {
                            detailError = i18next.t('services.trips.requestTrip.FailedObtainVehiclesData')//'Error al obtener los vehículos disponibles';
                        }
                        if (detailError.includes('No vehicles available for this day of the week')) {
                            detailError = i18next.t('services.trips.requestTrip.NoVehiclesForThisDayOfWeek');
                        }
                    }
                    if (detailError.includes('VehiclesWithWheelChairCapacityNotFound')) {
                        if (detailError.includes('No vehicles available with wheelchair capacity')) {
                            detailError = i18next.t('services.trips.requestTrip.VehiclesWithWheelChairCapacityNotFound')//'No hay vehículos disponibles con servicio de silla de ruedas';
                        }
                        if (detailError.includes('No vehicles available with luggage capacity')) {
                            detailError = i18next.t('services.trips.requestTrip.VehiclesWithLuggageCapacityNotFound')//'No hay vehículos disponibles con capacidad para llevar equipaje';
                        }
                    }
                    if (detailError.includes('RouteNotFound')) {
                        detailError = i18next.t('services.trips.requestTrip.RouteNotFound')//'No hay disponibilidad para el viaje solicitado. Probar modificando la fecha o el número de pasajeros.';
                    }
                    //RequestOutOfHours y RequestOutOfWeekDays
                    if (detailError.includes('RequestOutOfHours')) {
                        detailError = i18next.t('services.trips.requestTrip.RequestOutOfHours')//'El servicio no acepta peticiones de viaje a esta hora.';
                    }
                    if (detailError.includes('RequestOutOfWeekDays')) {
                        detailError = i18next.t('services.trips.requestTrip.RequestOutOfWeekDays')//'El servicio no acepta peticiones en este día de la semana.';
                    }
                    if (detailError.includes('VehicleOutsideWorkingHours')) {
                        detailError = i18next.t('services.trips.requestTrip.VehicleOutsideWorkingHours'); //"El servicio no acepta solicitudes este día de la semana"
                    }
                    if (detailError.includes('VehiclesInCalendarNotFound')) {
                        detailError = i18next.t('services.trips.requestTrip.VehiclesNotFound')//'No hay vehículos disponibles en el servicio';
                    }
                    if (detailError.includes('NoLineExpeditionWasFound')) {
                        detailError = i18next.t('services.trips.requestTrip.NoLineExpeditionWasFound')//'No hay expediciones en el servicio';
                        dispatch(openModalExpeditions(parsedError.object))
                    }
                    if (detailError.includes('LineExpeditionNotAvailable')) {
                        detailError = i18next.t('services.trips.requestTrip.LineExpeditionNotAvailable')//'No hay expediciones en el servicio';
                        dispatch(openModalExpeditions(parsedError.object))
                    }
                    if (detailError.includes('DuplicatedCustomerTrip')) {
                        detailError = i18next.t('services.trips.requestTrip.DuplicatedCustomerTrip')//"Este usuario ya tiene un viaje pendiente para esta expedición"
                    }
                    if (detailError.includes('TripHasReturn')) {
                        detailError = i18next.t(
                            'services.trips.requestTrip.TripHasReturn',
                        )
                    }
                    if (detailError.includes('ReturnTripInvalidDate')) {
                        detailError = i18next.t(
                            'services.trips.requestTrip.ReturnTripInvalidDate',
                        )
                    }
                    dispatch(failure(detailError));
                    dispatch(alertActions.error(detailError));
                }
                else {
                    //console.log("Error adding trip => ", error);
                    dispatch(failure(detailError));
                    dispatch(alertActions.error(detailError));
                }
                //console.log("detailError => ", detailError)
            }
        )
    }
    function request() {
        return { type: tripConstants.ADD_TRIP_REQUEST }
    }
    function success(data) {
        return { type: tripConstants.ADD_TRIP_SUCCESS, data }
    }
    function failure(error) {
        return { type: tripConstants.ADD_TRIP_FAILURE, error }
    }
}

function getTripsFromCustomer() {
    return dispatch => {
        dispatch(request());
        userService.getTripsFromCustomer().then(
            response => {
                //console.log("response gettripsfromcustomer=> ", response);
                dispatch(success(response))
            },
            error => {
                let detailError = i18next.t('services.trips.errorOccurs')//"Se ha producido un error";
                let detailErrorRedux = "";
                let status = '';
                try {
                    let parsedError = JSON.parse(error);
                    detailError = parsedError.detail;
                    detailErrorRedux = parsedError.detail;
                    status = parsedError.status;
                    // console.log("detailError => ", detailError);
                    // console.log("status => ", status);
                    // console.log("error => ", error);
                } catch (e) {
                    detailError = i18next.t('services.trips.errorOccurs')//'Se ha producido un error';
                }
                if (status == 404) {
                    if (detailError == 'TripsNotFound') {
                        detailError = i18next.t('services.trips.tripsNotFound')//'No se han encontrado viajes';
                    }
                }
                if (error === 401) {
                    dispatch(userActions.refreshToken());
                    setTimeout(() => {
                        dispatch(getTripsFromCustomer());
                        //dispatch(failure(error));
                        //dispatch(alertActions.error('Por favor vuelvalo a intentar...'));
                    }, 2000);
                }
                dispatch(failure(detailErrorRedux));
                if (detailErrorRedux != "TripsNotFound") {
                    dispatch(alertActions.error(detailError));
                } else {

                }


            }
        )
    }

    function request() {
        return { type: tripConstants.GET_TRIPS_CUSTOMER_REQUEST }
    }
    function success(data) {
        return { type: tripConstants.GET_TRIPS_CUSTOMER_SUCCESS, data }
    }
    function failure(error) {
        return { type: tripConstants.GET_TRIPS_CUSTOMER_FAILURE, error }
    }

}

function setTrip(trip) {
    return dispatch => {
        dispatch(success(trip));
    }
    function success(data) {
        return { type: tripConstants.SET_TRIP_SUCCESS, data }
    }
}

function setTripToCancelledByUser(tripId) {
    return dispatch => {
        dispatch(request());
        tripsService.setTripToCancelledByUser(tripId).then(
            response => {
                dispatch(success(response));
                dispatch(getTripsFromCustomer());
                if (response.cancellationMinutesExpired == true) {
                    dispatch(alertActions.warning(i18next.t('services.trips.cancelSuccessWithPenalty')));
                } else {
                    dispatch(alertActions.success(i18next.t('services.trips.cancelSuccess')));
                }
            },
            error => {
                if (error === 401) {
                    dispatch(userActions.refreshToken());
                    setTimeout(() => {
                        dispatch(setTripToCancelledByUser(tripId));
                    }, 2000);
                } else {
                    dispatch(failure(error));
                }
            }
        )
    }
    function request() {
        return { type: tripConstants.SET_TRIP_CANCEL_BY_USER_REQUEST }
    }
    function success(data) {
        return { type: tripConstants.SET_TRIP_CANCEL_BY_USER_SUCCESS, data }
    }
    function failure(error) {
        return { type: tripConstants.SET_TRIP_CANCEL_BY_USER_FAILURE, error }
    }
}

function setTripToAcceptedByUser(tripId) {
    return dispatch => {
        dispatch(request());
        tripsService.setTripToAcceptedByUser(tripId).then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(i18next.t('services.trips.acceptSuccess')));
                // setTimeout(() => {
                //     window.location.reload();
                // }, 1500);    
            },
            error => {
                if (error === 401) {
                    //console.log("error =>", error);
                    dispatch(userActions.refreshToken());
                    setTimeout(() => {
                        dispatch(setTripToAcceptedByUser(tripId));
                    }, 2000);
                } else {
                    dispatch(alertActions.error(i18next.t('services.trips.acceptError')));
                    dispatch(failure(error));
                }
            }
        )
    }
    function request() {
        return { type: tripConstants.SET_TRIP_ACCEPTED_REQUEST }
    }
    function success(data) {
        return { type: tripConstants.SET_TRIP_ACCEPTED_SUCCESS, data }
    }
    function failure(error) {
        return { type: tripConstants.SET_TRIP_ACCEPTED_FAILURE, error }
    }
}

function setTripToRejectedByUser(tripId) {
    return dispatch => {
        dispatch(request());
        tripsService.setTripToRejectedByUser(tripId).then(
            response => {
                //console.log("response => ", response);
                dispatch(alertActions.success(i18next.t('services.trips.rejectSuccess')));
                dispatch(success(response));
            },
            error => {
                if (error === 401) {
                    //console.log("error =>", error);
                    dispatch(userActions.refreshToken());
                    setTimeout(() => {
                        dispatch(setTripToRejectedByUser(tripId));
                    }, 2000);
                } else {
                    dispatch(alertActions.error(i18next.t('services.trips.rejectError')));
                    dispatch(failure(error));
                }
            }
        )
    }
    function request() {
        return { type: tripConstants.SET_TRIP_REJECTED_BY_USER_REQUEST }
    }
    function success(data) {
        return { type: tripConstants.SET_TRIP_REJECTED_BY_USER_SUCCESS, data }
    }
    function failure(error) {
        return { type: tripConstants.SET_TRIP_REJECTED_BY_USER_FAILURE, error }
    }
}

function selectServiceClose() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.SELECT_SERVICE_TRIP_CLOSE }
    }
}


function rejectTrip(id) {
    return dispatch => {
        dispatch(request(id))
        tripsService.rejectTrip(id).then(
            trip => {
                dispatch(multipleTripsActions.updateScheduleReturn(false))
                dispatch(success(trip))
                dispatch(alertActions.success(i18next.t('services.trips.rejectSuccess')))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(rejectTrip(id))
                } else {
                    dispatch(alertActions.error(i18next.t('services.trips.rejectError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: tripConstants.REJECT_TRIP_REQUEST, id }
    }

    function success(trip) {
        return { type: tripConstants.REJECT_TRIP_SUCCESS, trip }
    }

    function failure(error) {
        return { type: tripConstants.REJECT_TRIP_FAILURE, error }
    }
}

function setStops(service, stopType, stopList, dataTripRequest) {
    const originLon = dataTripRequest.origin ? dataTripRequest.origin.position.lng : dataTripRequest.pickUpLocation.lon;
    const originLat = dataTripRequest.origin ? dataTripRequest.origin.position.lat : dataTripRequest.pickUpLocation.lat;
    const destinationLon = dataTripRequest.destination ? dataTripRequest.destination.position.lng : dataTripRequest.dropOffLocation.lon;
    const destinationLat = dataTripRequest.destination ? dataTripRequest.destination.position.lat : dataTripRequest.dropOffLocation.lat;

    return dispatch => {
        let myCustomAddressObject = {
            'id': MY_CUSTOM_ADDRESS,
            'address': stopType == 'origin' ? dataTripRequest.originAddress : dataTripRequest.destinationAddress,
            'isAssinged': true,
            'name': i18next.t('services.trips.myAddress'),
            'point': {
                'lat': stopType == 'origin' ? originLat : destinationLat,
                'lon': stopType == 'origin' ? originLon : destinationLon,
            }
        }
        let hasSelectedMyCustomAddress = store.getState().tripReducer.hasSelectedMyCustomAddress
        if (stopType == 'origin') {
            if (REGULAR_SERVICES_TYPES.includes(service.serviceType) || service?.originZone?.zoneType != zoneStopTypes.WithoutStops) {
                dispatch(openModalRequestTrip(i18next.t('services.trips.mustSelectOriginStop')));
            }
            let myAddressIsEqualStop = false;
            let found = false;
            //console.log("myAddress point => ", myCustomAddressObject.point)
            stopList.map((stop, index) => {
                //console.log("stop here ******** => ", stop.point);
                if ((stop.point.lat == myCustomAddressObject.point.lat) && (stop.point.lon == myCustomAddressObject.point.lon) && !found) {
                    //console.log("el teu punt d'origen coincideix amb una parada => ", stop.name);
                    myAddressIsEqualStop = true;
                    stopList[index] = { ...stop, "myAddressIsEqualStop": myAddressIsEqualStop }
                    found = true;
                }
            })
            //SHOW MY ORIGINAL ADDRESS (BUT NO SELECTABLE)
            if (!found) {
                let newObject = { ...myCustomAddressObject, 'selectable': checkMyDirectionCanSelect(service, "origin", hasSelectedMyCustomAddress) }
                stopList.unshift(newObject);
            }
            //console.log("myAddressIsEqualStop => ", myAddressIsEqualStop);
            let exists = stopList.find(stop => stop.id == MY_CUSTOM_ADDRESS);
            //console.log("exists origin => ", exists);
            if (service?.originZone?.zoneType == zoneStopTypes.MixedOriginOrDestination ||
                service?.originZone?.zoneType == zoneStopTypes.MixedOriginAndDestination) {
                //console.log("zone 1");

                if (exists === undefined && myCustomAddressObject != null && !myAddressIsEqualStop) {
                    //console.log('zone 2');
                    stopList.unshift(myCustomAddressObject);
                }
            }
        } else {
            if (service?.destinationZone?.zoneType != zoneStopTypes.WithoutStops) {
                dispatch(openModalRequestTrip(i18next.t('services.trips.mustSelectDestinationStop')));
            }
            let myAddressIsEqualStop = false;
            let found = false;
            //console.log("myAddress point => ", myCustomAddressObject.point)
            stopList.map((stop, index) => {
                //console.log("stop here ******** => ", stop.point);
                if ((stop.point.lat == myCustomAddressObject.point.lat) && (stop.point.lon == myCustomAddressObject.point.lon) && !found) {
                    //console.log("el teu punt d'origen coincideix amb una parada => ", stop.name);
                    myAddressIsEqualStop = true;
                    stopList[index] = { ...stop, "myAddressIsEqualStop": myAddressIsEqualStop, selectable: checkMyDirectionCanSelect(service, "destination", hasSelectedMyCustomAddress) }
                    found = true;
                }
            })
            //SHOW MY ORIGINAL ADDRESS (BUT NO SELECTABLE)
            if (!found) {
                let newObject = { ...myCustomAddressObject, 'selectable': checkMyDirectionCanSelect(service, "destination", hasSelectedMyCustomAddress) }
                stopList.unshift(newObject);
            }
            //console.log("myAddressIsEqualStop => ", myAddressIsEqualStop);
            let exists = stopList.find(stop => stop.id == MY_CUSTOM_ADDRESS);
            //console.log("exists destination => ", exists);
            if ((service?.destinationZone?.zoneType == zoneStopTypes.MixedOriginOrDestination && hasSelectedMyCustomAddress == false) ||
                (service?.destinationZone?.zoneType == zoneStopTypes.MixedOriginAndDestination) ||
                (service?.originZone?.zoneType == zoneStopTypes.MixedOriginAndDestination && service?.destinationZone?.zoneType == zoneStopTypes.MixedOriginOrDestination)) {
                //console.log("zone 3");
                if (exists === undefined && myCustomAddressObject != null && !myAddressIsEqualStop) {
                    //console.log("zone 4");
                    stopList.unshift(myCustomAddressObject);
                }
            }
        }
        //console.log("stopList al final");
        dispatch(success(stopList, stopType, service));
    }

    function success(stopList, stopType, selectedService) {
        if (stopType == 'origin') {
            return { type: tripConstants.SET_ORIGIN_STOPS_LIST_SUCCESS, stopList, selectedService }
        } else {
            return { type: tripConstants.SET_DESTINATION_STOPS_LIST_SUCCESS, stopList, selectedService }
        }
    }
}

function selectedMyCustomAddress(bool) {
    return dispatch => {
        dispatch(success(bool))
    }
    function success(bool) {
        return { type: tripConstants.SET_SELECTED_MY_CUSTOM_ADDRESS_SUCCESS, bool }
    }
}

function selectedStop(stop, stopType, dataTripRequest, service, direction) {
    const originLon = dataTripRequest.origin ? dataTripRequest.origin.position.lng : dataTripRequest.pickUpLocation.lon;
    const originLat = dataTripRequest.origin ? dataTripRequest.origin.position.lat : dataTripRequest.pickUpLocation.lat;
    const destinationLon = dataTripRequest.destination ? dataTripRequest.destination.position.lng : dataTripRequest.dropOffLocation.lon;
    const destinationLat = dataTripRequest.destination ? dataTripRequest.destination.position.lat : dataTripRequest.dropOffLocation.lat;

    let dataTripRequestCopy = { ...dataTripRequest }
    return dispatch => {
        let hasSelectedMyCustomAddress = false;
        let myCustomAddressObject = {
            'id': MY_CUSTOM_ADDRESS,
            'address': stopType == 'origin' ? dataTripRequest.originAddress : dataTripRequest.destinationAddress,
            'isAssinged': true,
            'name': i18next.t('services.trips.myAddress'),
            'point': {
                'lat': stopType == 'origin' ? originLat : destinationLat,
                'lon': stopType == 'origin' ? originLon : destinationLon,
            }
        }
        if (stop.id == myCustomAddressObject.id) {
            hasSelectedMyCustomAddress = true;
        }
        dataTripRequestCopy = {
            ...dataTripRequestCopy,
            'destination': {
                'name': dataTripRequest.destination ? dataTripRequest.destination.name : dataTripRequest.dropOffLocation.address,
                'position': {
                    'lat': stopType == 'origin' ? destinationLat : stop.position.lat,
                    'lng': stopType == 'origin' ? destinationLon : stop.position.lng,
                },
                'id': stopType == 'origin' ? stop.id : dataTripRequest.destination ? dataTripRequest.destination.id : dataTripRequest.dropOffLocation.id
            },
            'destinationAddress': stopType == 'origin' ? dataTripRequest.destinationAddress : /*stop.name + ' / ' + */stop.address,
            'origin': {
                'name': dataTripRequest.origin ? dataTripRequest.origin.name : dataTripRequest.pickUpLocation.address,
                'position': {
                    'lat': stopType == 'destination' ? originLat : stop.position.lat,
                    'lng': stopType == 'destination' ? originLon : stop.position.lng,
                },
                'id': stopType == 'destination' ? stop.id : dataTripRequest.origin ? dataTripRequest.origin.id : dataTripRequest.pickUpLocation.id
            },
            'originAddress': stopType == 'destination' ? dataTripRequest.originAddress : /*stop.name + ' / ' + */stop.address,
            'serviceId': service.id,
        }

        let trip = factory.createTripRequest(dataTripRequestCopy)


        if (stopType == 'origin') {
            dispatch(success(stopType, dataTripRequestCopy, hasSelectedMyCustomAddress, stop));
            //SI DESTÍ ES DE TIPUS 20 (WITHOUTSTOPS) PASSI DIRECTAMENT A SOL·LICITAR VIATGE AMB L'ADREÇA ENTRADA
            if (service.destinationZone?.zoneType == zoneStopTypes.WithoutStops || (!hasSelectedMyCustomAddress && service?.destinationZone?.zoneType == zoneStopTypes.MixedOriginOrDestination)) {
                dispatch(requestTrip(trip, service.id));
            }
        }
        else { //stopType!='origin' => 'destination'
            dispatch(success(stopType, dataTripRequestCopy, hasSelectedMyCustomAddress, stop));
            if (service.serviceType != SERVICES_TYPE.regularWithSchedule) {
                dispatch(requestTrip(trip, service.id));
            } else {
                dispatch(regularLine_actions.getRegularLineStopScheduledHours(dataTripRequestCopy.pickupStartTime, dataTripRequestCopy.destination.id, dataTripRequestCopy.origin.id, requestTrip.isRequestByDropOff))
            }
        }
    }

    function success(stopType, newDataTripRequest, hasSelectedMyCustomAddress, selectedStop) {
        if (stopType == 'origin') { //assignem origen
            return { type: tripConstants.SET_SELECTED_STOP_ORIGIN_SUCCESS, newDataTripRequest, hasSelectedMyCustomAddress, selectedStop }
        } else { //assignem destinació
            //console.log("assignem a dataTripRequest.destination.position = stop.position");
            return { type: tripConstants.SET_SELECTED_STOP_DESTINATION_SUCCESS, newDataTripRequest, hasSelectedMyCustomAddress, selectedStop }
        }
    }
}


function checkMyDirectionCanSelect(service, direction, hasSelectedMyCustomAddress) {
    if (!REGULAR_SERVICES_TYPES.includes(service.serviceType)) {
        if (direction !== "origin") {
            if (hasSelectedMyCustomAddress)
                return [zoneStopTypes.MixedOriginAndDestination, zoneStopTypes.WithoutStops].includes(service?.destinationZone?.zoneType)
            else
                return [zoneStopTypes.WithoutStops, zoneStopTypes.MixedOriginAndDestination, zoneStopTypes.MixedOriginOrDestination].includes(service?.destinationZone?.zoneType)
        } else
            return ![zoneStopTypes.WithStops].includes(service?.originZone?.zoneType)
    } else
        return false
}



function closeModalRequestTrip() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.CLOSE_MODAL_REQUEST_TRIP }
    }
}

function openModalRequestTrip(message) {
    return dispatch => {
        dispatch(request(message))
    }

    function request(message) {
        return { type: tripConstants.OPEN_MODAL_REQUEST_TRIP, message }
    }
}

function clearOrigin(selectedService, stopsType, stopList, oldDataTripRequest) {
    return dispatch => {
        dispatch(setStops(selectedService, stopsType, stopList, oldDataTripRequest));
        dispatch(success(oldDataTripRequest));
    }

    function success() {
        return { type: tripConstants.CLEAR_SELECTED_STOP_ORIGIN_SUCCESS, oldDataTripRequest }
    }
}

function resetData() {
    return dispatch => {
        dispatch(success())
    }

    function success() {
        return { type: tripConstants.RESET_DATA_TRIP_REDUCER_SUCCESS }
    }
}

function repeatTrip(trip, isReturn = false) {
    return dispatch => {
        //console.log("trip en repeattrip de tripActions => ", trip)
        let requestTrip = {
            dropOffLocation: getDropOffLocationAddress(trip),
            pickUpLocation: getPickUpLocationAddress(trip),
            hasWheelChair: trip.hasWheelChair,
            numPassengers: trip.numPassengers,
            luggage: trip.luggage,
            fullName: trip.customerName,
            customerId: trip.customerId,
            isOnRelatedCustomerBehalf: trip.isOnRelatedCustomerBehalf,
            relatedCustomer: trip.relatedCustomer,
            minPickUpTime: trip.scheduleDropOffTime,
            fistAvailableDate: moment(trip.scheduleDropOffTime).add(15, 'minutes').toDate(),
            outboundTripId: isReturn ? trip.tripId : null
        }
        dispatch(success(requestTrip));

        function success(tripToReturn) {
            return { type: tripConstants.SET_REPEAT_TRIP, tripToReturn, isReturn: true }
        }
    }
}

function closeReturnTripModal() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.CLOSE_RETURN_TRIP_MODAL }
    }
}

function cleanRepeatTrip() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.CLEAN_REPEAT_TRIP }
    }
}

function cleanTrip() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.CLEAN_TRIP }
    }

}

function setModifyTripData(currentTrip) {
    return dispatch => {
        dispatch(success(currentTrip));
    }
    //if argument currentTrip == null then modifyTripData = null and isModifyTrip = false in tripReducer
    function success(currentTrip) {
        return { type: tripConstants.SET_MODIFY_TRIP_DATA_SUCCESS, currentTrip }
    }
}

function resetModifyData() {
    return dispatch => {
        dispatch(success());
    }

    function success() {
        return { type: tripConstants.RESET_MODIFY_DATA_SUCCESS }
    }
}

function modifyTrip({ origin, destination, isRequestByDropOff, numPassengers, pickupStartTime, customerName, hasWheelChair, /*babyCart = false,*/ luggage, serviceId, pushToken, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf = false, relatedCustomer = null, passengerId }) {
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        userService.modifyTrip({ origin, destination, isRequestByDropOff, numPassengers, pickupStartTime, customerName, hasWheelChair, /*babyCart,*/ luggage, serviceId, pushToken, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf, relatedCustomer, passengerId }).then(
            response => {
                dispatch(success(response));
            },
            error => {
                let detailError = i18next.t('services.trips.errorOccurs')//"Se ha producido un error";
                let status = '';
                let parsedError
                try {
                    parsedError = JSON.parse(error);
                    detailError = parsedError.detail;
                    status = parsedError.status;
                } catch (e) {
                    detailError = i18next.t('services.trips.errorOccurs')//'Se ha producido un error';
                }
                if (status == 500) {
                    if (detailError.includes('TripNotProcessed')) {
                        detailError = i18next.t('services.trips.requestTrip.TripNotProcessed')//'El viaje no se ha podido procesar';
                        dispatch(failure(detailError));
                    }
                }
                if (error === 401) {
                    //console.log("error 401")
                    dispatch(userActions.refreshToken());
                    setTimeout(() => {
                        //dispatch(modifyTrip(origin, destination, numPassengers, pickupStartTime, customerName, hasWheelChair, /*babyCart,*/ luggage, serviceId, pushToken, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf, relatedCustomer, passengerId))
                        dispatch(failure(error));
                        dispatch(alertActions.error(i18next.t('services.trips.errorRepeatAgain')));
                    }, 2000);
                }
                if (status == 400) {
                    if (detailError.includes('InvalidParameters')) {
                        detailError = i18next.t('services.trips.requestTrip.InvalidParameters')//'Parámetros introducidos no válidos';
                    }
                    if (detailError.includes('ServiceZonesNotFound')) {
                        detailError = i18next.t('services.trips.requestTrip.ServiceZonesNotFound')//'No se ha encontrado ninguna zona en el sistema dada la posición de origen o la posición de destino';
                    }
                    if (detailError.includes('ServicesNotFound')) {
                        detailError = i18next.t('services.trips.requestTrip.ServicesNotFound')//'No se han encontrado servicios activos';
                    }
                    if (detailError.includes('VehiclesNotFound')) {
                        if (detailError.includes('No vehicles available for given trip')) {
                            detailError = i18next.t('services.trips.requestTrip.VehiclesNotFound')//'No hay vehículos disponibles en el servicio';
                        }
                        if (detailError.includes('Only Freelance vehicles available in service')) {
                            detailError = i18next.t('services.trips.requestTrip.OnlyFreelanceVehiclesAvailable')//'Solamente hay disponibles vehículos de tipología Freelance';
                        }
                        if (detailError.includes('Failed to obtain Vehicles data')) {
                            detailError = i18next.t('services.trips.requestTrip.FailedObtainVehiclesData')//'Error al obtener los vehículos disponibles';
                        }
                        if (detailError.includes('No vehicles available for this day of the week')) {
                            detailError = i18next.t('services.trips.requestTrip.NoVehiclesForThisDayOfWeek');
                        }
                    }
                    if (detailError.includes('VehiclesWithWheelChairCapacityNotFound')) {
                        if (detailError.includes('No vehicles available with wheelchair capacity')) {
                            detailError = i18next.t('services.trips.requestTrip.VehiclesWithWheelChairCapacityNotFound')//'No hay vehículos disponibles con servicio de silla de ruedas';
                        }
                        if (detailError.includes('No vehicles available with luggage capacity')) {
                            detailError = i18next.t('services.trips.requestTrip.VehiclesWithLuggageCapacityNotFound')//'No hay vehículos disponibles con capacidad para llevar equipaje';
                        }
                    }
                    if (detailError.includes('RouteNotFound')) {
                        detailError = i18next.t('services.trips.requestTrip.RouteNotFound')//'No hay disponibilidad para el viaje solicitado. Probar modificando la fecha o el número de pasajeros.';
                    }
                    if (detailError.includes('RequestOutOfHours')) { //RequestOutOfHours
                        detailError = i18next.t('services.trips.requestTrip.RequestOutOfHours')//'El servicio no acepta peticiones de viaje a esta hora.';
                    }
                    if (detailError.includes('RequestOutOfWeekDays')) { // RequestOutOfWeekDays
                        detailError = i18next.t('services.trips.requestTrip.RequestOutOfWeekDays')//'El servicio no acepta peticiones en este día de la semana.';
                    }
                    if (detailError.includes('VehiclesInCalendarNotFound')) {
                        detailError = i18next.t('services.trips.errorOccurs') //'Se ha producido un error';
                    }
                    if (detailError.match("ModificationMinutesExpired")) {
                        detailError = i18next.t('services.trips.modifyTrip.ModificationMinutesExpired');//"El tiempo de modificación ha expirado"
                    }
                    if (detailError.includes('NoLineExpeditionWasFound')) {
                        detailError = i18next.t('services.trips.requestTrip.NoLineExpeditionWasFound')//'No hay expediciones en el servicio';
                        dispatch(openModalExpeditions(parsedError.object))
                    }
                    if (detailError.includes('InvalidZoneStopTypeCombination')) {
                        detailError = i18next.t('services.trips.requestTrip.InvalidZoneStopTypeCombination')
                    }
                    dispatch(failure(detailError));
                    dispatch(alertActions.error(detailError));
                }
                else {
                    dispatch(failure(detailError));
                    dispatch(alertActions.error(detailError));
                }
            }
        )

    }
    function request() {
        return { type: tripConstants.MODIFY_TRIP_REQUEST }
    }
    function success(data) {
        return { type: tripConstants.MODIFY_TRIP_SUCCESS, data }
    }
    function failure(error) {
        return { type: tripConstants.MODIFY_TRIP_FAILURE, error }
    }
}

function acceptModifyTrip(newTripId, oldTripId) {
    const ERROR = "ERROR_ACCEPT_NODIFY_TRIP"
    return dispatch => {
        dispatch(request());
        userService.acceptModifyTrip(newTripId, oldTripId).then(
            response => {
                //console.log("response => ", response)
                if (response == 200) {
                    dispatch(success(response));
                    //console.log("acceptmodifytrip in tripActions after success()")
                    dispatch(resetModifyData());
                    dispatch(alertActions.success(i18next.t('services.trips.modifyTrip.successModifyTrip')));
                } else {
                    dispatch(failure(ERROR))
                    dispatch(alertActions.error(i18next.t('services.trips.modifyTrip.errorModifyTrip')));
                }
            },
            error => {
                //console.log("error acceptModifyTrip in tripActions **** => ", error);
                if (error === 401) {
                    dispatch(userActions.refreshToken());
                    setTimeout(() => {
                        dispatch(acceptModifyTrip(newTripId, oldTripId));
                    }, 2000);
                } else {
                    dispatch(failure(error))
                }
            }
        )
    }

    function request() {
        return { type: tripConstants.ACCEPT_MODIFY_TRIP_REQUEST }
    }
    function success(data) {
        return { type: tripConstants.ACCEPT_MODIFY_TRIP_SUCCESS, data }
    }
    function failure(error) {
        return { type: tripConstants.ACCEPT_MODIFY_TRIP_FAILURE, error }
    }
}


function getById(id) {
    return dispatch => {
        dispatch(request(id))
        tripsService.getById(id).then(
            trip => {
                dispatch(success(trip))
            },
            error => {
                if (error === 401 && existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getById(id))
                } else {
                    dispatch(alertActions.error(i18next.t('services.trips.getByIdError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: tripConstants.GETBYID_REQUEST, id }
    }

    function success(trip) {
        return { type: tripConstants.GETBYID_SUCCESS, trip }
    }

    function failure(error) {
        return { type: tripConstants.GETBYID_FAILURE, error }
    }
}

function getWeekDays() {
    return dispatch => {
        dispatch(request())
        tripsService.getWeekDays().then(
            result => {
                const days = makeEnum(result)
                dispatch(success(days))
            },
            error => {
                if (error === 401 && existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getWeekDays())
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.GET_WEEK_DAYS_REQUEST }
    }

    function success(days) {
        return { type: tripConstants.GET_WEEK_DAYS_SUCCESS, days }
    }

    function failure(error) {
        return { type: tripConstants.GET_WEEK_DAYS_FAILURE, error }
    }
}

function getTripStatuses() {
    return dispatch => {
        dispatch(request())
        const lang = i18next.language
        tripsService.getTripStatuses().then(
            results => {
                const prof = makeEnum(results)
                dispatch(success(prof))
            },
            error => {
                if (error === 401) {
                    dispatch(userActions.refreshToken())
                    dispatch(getTripStatuses(lang))
                } else {
                    dispatch(alertActions.error(i18next.t('services.trips.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.GET_TRIPS_TYPES_REQUEST }
    }

    function success(results) {
        return { type: tripConstants.GET_TRIPS_TYPES_SUCCESS, results }
    }

    function failure(error) {
        return { type: tripConstants.GET_TRIPS_TYPES_FAILURE, error }
    }
}

function makeEnum(enumObject) {
    let all = []
    for (let key in enumObject) {
        all.push({
            id: key,
            name: enumObject[key],
        })
    }
    return all
}


function openModalExpeditions(expeditions) {
    return dispatch => {
        dispatch(request(expeditions))
    }

    function request(expeditions) {
        return { type: tripConstants.OPEN_MODAL_SHOW_EXPEDITIONS, expeditions }
    }
}

function closeModalExpeditions(expeditions) {
    return dispatch => {
        dispatch(request(expeditions))
    }

    function request() {
        return { type: tripConstants.CLOSE_MODAL_SHOW_EXPEDITIONS }
    }
}