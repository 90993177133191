import { Grid } from "@material-ui/core"
import React, { useCallback, useEffect, useState } from 'react';
import AlertDialog from "../../alert-dialog";
import i18next from "i18next";
import { ErrorsMultipleRequestTripComponent, ListOfTripsPlannedComponent, stylesMultipleTrip, withListOfTrips } from "./details";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { multipleTripsActions } from "../../../redux/actions/multipleTrips_action";
import { SERVICES_TYPE } from "../../../constants/types";
import { useModal } from "../../../hooks/useModal";
import { useSelectTime } from "./hooks/selectTime.hook";
import { useErrorsMultipleRequestTrip } from "./hooks/errorsMultipleTrip.hook";
import Spinner from "../../shared/spinner";
import { changeTimeToTrip, isMultipleTripOutbound } from "../tools";
import { useSelectRequestType } from "./hooks/selectRequestType.hook";
import { withSelectNewTimeComponent } from "./components/datePickers";

const MultipleTrips = ({ cancelTripPlanned }) => {

    const [trip, setTrip] = useState(null)
    const multipleTripReducer = useSelector((state) => state.multipleTripReducer)
    const dispatch = useDispatch()
    const serviceType = useSelector((state) => state.multipleTripReducer.serviceType)

    const listOfTrips = multipleTripReducer.results

    const { open: openError, openModal: openModalError, closeModal: closeModalError } = useModal()
    const { open: openRetry, openModal: openModalRetry, closeModal: closeModalRetry } = useModal()
    const { open: openCancel, openModal: openModalCancel, closeModal: closeModalCancel } = useModal()
    const { open: openScheduleReturn, openModal: openModalScheduleReturn, closeModal: closeModalScheduleReturn } = useModal()
    const { newTimeToTrip: newTimeToRetryTrip, setNewTime: setNewTimeToRetryTrip } = useSelectTime()
    const { newTimeToTrip: newTimeToReturnTrip, setNewTime: setNewTimeToReturnTrip } = useSelectTime()
    const selectedTripsToReturn = useSelector((state) => state.multipleTripReducer.selectedTripsToReturn)
    const currentUser = useSelector((state) => state.customerReducer.currentUser)

    const { isRequestByDropOff, onChangeRequestType } = useSelectRequestType()
    const { errors, updateErrors } = useErrorsMultipleRequestTrip()

    const handleMouseOver = (trip) => {
        if (trip) {
            updateErrors(trip.apiErrors)
            openModalError()
        }
    }

    const handleScheduleReturn = () => {
        openModalScheduleReturn()
    }

    useEffect(() => {
        return () => dispatch(multipleTripsActions.updateScheduleReturn(false))
    }, [dispatch])


    const handleAcceptScheduleReturn = useCallback(() => {
        const firstTripToScheduleReturn = listOfTrips.find(item => item.tripId == selectedTripsToReturn.filter(item => item.checked)[0].id).tripResponse
        const { birthDate, deviceDetails } = currentUser
        const pushTokenList = []
        deviceDetails.forEach((item) => {
            pushTokenList.push(item.pushToken)
        })

        const pushTokenString = pushTokenList.join(",")

        dispatch(multipleTripsActions.initScheduleReturnTrip(firstTripToScheduleReturn, newTimeToReturnTrip, pushTokenString, birthDate, isRequestByDropOff))
        dispatch(multipleTripsActions.updateScheduleReturn(true))

        closeModalScheduleReturn()

    }, [listOfTrips, currentUser, dispatch, newTimeToReturnTrip, isRequestByDropOff, closeModalScheduleReturn, selectedTripsToReturn])

    const handleCancelTrip = (trip) => {
        if (trip) {
            setTrip(trip)
            openModalCancel()
        }
    }

    const handleRetryTrip = (trip) => {
        if (trip) {
            setTrip(trip)
            openModalRetry()
            if (![SERVICES_TYPE.regularWithSchedule].includes(serviceType))
                setNewTimeToRetryTrip(moment(trip.requestPickUpTime).toDate())
        }
    }

    const handleRetryTripAccept = useCallback(() => {
        if (!newTimeToRetryTrip) return
        const tripIdOriginal = trip.tripIdRetry
        const newTime = changeTimeToTrip(trip, newTimeToRetryTrip)
        const body = {
            tripId: tripIdOriginal,
            requestPickUpStartTime: newTime.toDate().toISOString(),
            isRequestByDropOff: isRequestByDropOff,
            lang: i18next.language
        }
        dispatch(multipleTripsActions.processMultipleTripRetry(body, trip.tripId, () => closeModalRetry()))

    }, [newTimeToRetryTrip, trip, isRequestByDropOff, dispatch, closeModalRetry])

    const isPending = multipleTripReducer.pending
    const classes = stylesMultipleTrip()

    const getMinDateFromList = useCallback(() => {
        const tripOutbound = listOfTrips?.find((item) => isMultipleTripOutbound(item?.tripResponse))?.tripResponse
        if (tripOutbound) {
            const dateOfTrip = moment(tripOutbound.schedulePickUpTime)
            const minDate = moment().set({ 'hours': dateOfTrip.hours(), 'minutes': dateOfTrip.minutes() }).add(15, 'minutes')
            return minDate.toDate()
        }
        return null
    }, [listOfTrips])

    const ComponentWrapped = withListOfTrips(ListOfTripsPlannedComponent)

    return (
        <Grid container className={classes.container}>
            <ComponentWrapped
                handleMouseOver={handleMouseOver}
                handleCancelTrip={handleCancelTrip}
                handleRetryTrip={handleRetryTrip}
                serviceType={serviceType}
                handleScheduleReturn={handleScheduleReturn}
            />
            <AlertDialog
                open={openError}
                onClickAccept={() => closeModalError()}
                onClose={() => closeModalError()}
                title={""}
                content={
                    () => <ErrorsMultipleRequestTripComponent
                        errors={errors}
                    />
                }
            />
            <AlertDialog
                open={openCancel}
                title={isPending ? i18next.t('form.multipleBook.cancellingTrip') : i18next.t('form.alert_dialog.titleDelete')}
                onClickAccept={() => {
                    cancelTripPlanned(trip.tripId, () => closeModalCancel())
                }}
                conditionDisableCancel={isPending}
                conditionDisableAccept={isPending}
                content={
                    () => isPending ? <Spinner loading={isPending} /> : null
                }
                onClickCancel={() => closeModalCancel()}
            />
            <AlertDialog
                open={openRetry}
                onClickCancel={() => closeModalRetry()}
                title={isPending ? i18next.t('form.multipleBook.retringTrip') : i18next.t('form.multipleBook.retryTripTitle')}
                content={() =>
                    withSelectNewTimeComponent(serviceType, trip.pickupHours, newTimeToRetryTrip, setNewTimeToRetryTrip, isPending, isRequestByDropOff, onChangeRequestType)
                }
                conditionDisableAccept={newTimeToRetryTrip == '' || isPending}
                conditionDisableCancel={isPending}
                onClickAccept={handleRetryTripAccept}
            />
            <AlertDialog
                open={openScheduleReturn}
                onClickCancel={() => closeModalScheduleReturn()}
                title={i18next.t('form.multipleBook.returnScheduleTripTitle')}
                content={() =>
                    withSelectNewTimeComponent(serviceType, [], newTimeToReturnTrip, setNewTimeToReturnTrip, isPending, isRequestByDropOff, onChangeRequestType, getMinDateFromList())
                }
                conditionDisableAccept={newTimeToReturnTrip == '' || isPending}
                conditionDisableCancel={isPending}
                onClickAccept={handleAcceptScheduleReturn}
            />
        </Grid>
    )
}

export default MultipleTrips