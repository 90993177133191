import i18next from "i18next"
import { multipleTripConstants } from "../../constants/multipleTrip_constants"
import { tripsService } from "../../services/tripsService"
import { alertActions } from "./alert_actions"
import { factory } from "../../helpers/factory"
import { tripActions } from "./trip_actions"
import { tripConstants } from "../../constants/trip_constants"
import moment from "moment"
import { userService } from "../../services/userService"
import { userActions } from "./user_actions"

export const multipleTripsActions = {
    processMultipleTrip,
    setOpenModalSelectFrecuency,
    setOpenModalShowDetails,
    cancelTripPlanned,
    processMultipleTripRetry,
    getTripsFromMultipleRequest,
    updateSelectedTripsToReturn,
    processMultipleTripReturn,
    updateScheduleReturn,
    initScheduleReturnTrip,
    acceptTripReturn,
    processMultipleTripOutboundAndReturn,
    setPreviewTrip,
}

function processMultipleTrip(body) {
    return dispatch => {
        dispatch(request())
        tripsService.processMultipleTrip(body).then(
            results => {
                results.forEach(element => {
                    element.tripIdRetry = body.tripId
                });

                const res = {
                    previewTrip: results[0].tripResponse,
                    results
                }

                dispatch(success(res))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken());
                    processMultipleTrip(body)
                } else
                    dispatch(failure(error))
            }
        )
    }
    function request() {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_REQUEST }
    }

    function success(results) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_SUCCESS, results }
    }

    function failure(error) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_FAILURE, error }
    }
}

function getTripsFromMultipleRequest(tripId) {
    return dispatch => {
        dispatch(request())
        tripsService.getTripsFromMultipleRequest(tripId).then(
            results => {
                dispatch(tripActions.setTrip(results[0].tripResponse))
                dispatch(multipleTripsActions.setPreviewTrip(results.find(item => item.tripId == tripId).tripResponse))
                dispatch(success(results))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken());
                    getTripsFromMultipleRequest(tripId)
                } else
                    dispatch(failure(error))
            }
        )
    }
    function request() {
        return { type: multipleTripConstants.GET_TRIPS_MULTIPLE_REQUEST }
    }

    function success(results) {
        return { type: multipleTripConstants.GET_TRIPS_MULTIPLE_SUCCESS, results }
    }

    function failure(error) {
        return { type: multipleTripConstants.GET_TRIPS_MULTIPLE_FAILURE, error }
    }
}

function setOpenModalSelectFrecuency(value, tripId, requestPickUpStartTime, serviceType) {
    return dispatch => {
        dispatch(request(value, tripId, requestPickUpStartTime, serviceType))
    }
    function request(value) {
        return { type: multipleTripConstants.OPEN_MODAL_SELECT_FRECUENCY, value, tripId, requestPickUpStartTime, serviceType }
    }
}

function setOpenModalShowDetails(value) {
    return dispatch => {
        dispatch(request(value))
    }
    function request(value) {
        return { type: multipleTripConstants.SHOW_MODAL_DETAILS, value }
    }
}

function cancelTripPlanned(tripId, callback) {
    return dispatch => {
        dispatch(request())
        tripsService.cancelTrip(tripId).then(
            trip => {
                dispatch(success(tripId))
                if (trip.cancellationMinutesExpired) {
                    dispatch(alertActions.warning(i18next.t('services.trips.CancellationMinutesExpired')))
                }
                if (callback)
                    callback()
                dispatch(alertActions.success(i18next.t('services.trips.cancelSuccess')))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken());
                    cancelTripPlanned(tripId, callback);
                } else
                    dispatch(failure(error))
            }
        )
    }

    function request() {
        return { type: multipleTripConstants.CANCEL_TRIP_REQUEST }
    }

    function success(tripId) {
        return { type: multipleTripConstants.UPDATE_LIST_TRIPS, tripId }
    }

    function failure(error) {
        return { type: multipleTripConstants.CANCEL_TRIP_FAILURE, error }
    }
}

function processMultipleTripRetry(body, tripIdToRetry, callback) {
    return dispatch => {
        dispatch(request())
        tripsService.processMultipleTripRetry(body).then(
            result => {
                dispatch(success(result, tripIdToRetry))
                if (callback) callback()
                if (result.hasErrors) {
                    dispatch(alertActions.error(i18next.t('services.trips.multipleBook.retryError')))
                } else {
                    dispatch(alertActions.success(i18next.t('services.trips.multipleBook.retrySuccess')))
                }
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken());
                    processMultipleTripRetry(body, tripIdToRetry, callback)
                } else
                    dispatch(failure(error))
            }
        )
    }
    function request() {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_REQUEST }
    }

    function success(result, tripIdToRetry) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_SUCCESS, result, tripIdToRetry }
    }

    function failure(error) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_FAILURE, error }
    }
}


function updateSelectedTripsToReturn(selectedValues) {
    return dispatch => {
        dispatch(request(selectedValues))
    }
    function request(selectedValues) {
        return { type: multipleTripConstants.UPDATE_SELECTED_TRIPS, selectedValues }
    }
}

function updateListOfTrips(array) {
    return dispatch => {
        dispatch(request(array))
    }

    function request(array) {
        return {
            type: multipleTripConstants.UPDATE_TRIPS_TO_LIST_EXIST, array
        }
    }
}


function processMultipleTripReturn(body) {
    return dispatch => {
        dispatch(request())
        tripsService.processMultipleTripReturn(body).then(
            results => {
                results.forEach(element => {
                    element.tripIdRetry = body.tripId
                });

                dispatch(success(results))
                dispatch(updateListOfTrips(results))
                dispatch(updateScheduleReturn(false))
                dispatch(alertActions.success(i18next.t('services.trips.acceptSuccess')))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken());
                    dispatch(processMultipleTripReturn(body));
                } else
                    dispatch(failure(error))
            }
        )
    }
    function request() {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETURN_REQUEST }
    }

    function success(results) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETURN_SUCCESS, results }
    }

    function failure(error) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETURN_FAILURE, error }
    }
}
function setPreviewTrip(trip) {
    return dispatch => {
        dispatch(request(trip))
    }

    function request(trip) {
        return { type: multipleTripConstants.SET_PREVIEW_TRIP, trip }
    }
}
function processMultipleTripOutboundAndReturn(body) {
    return dispatch => {
        dispatch(request())
        tripsService.processMultipleTripOutboundAndReturn(body).then(
            results => {
                results.forEach(element => {
                    element.tripIdRetry = body.tripId
                });
                const res = {
                    previewTrip: results[0].tripResponse,
                    results
                }

                dispatch(success(res))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken());
                    processMultipleTrip(body)
                } else
                    dispatch(failure(error))
            }
        )
    }
    function request() {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_REQUEST }
    }

    function success(results) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_SUCCESS, results }
    }

    function failure(error) {
        return { type: multipleTripConstants.PROCCESS_MULTIPLE_TRIP_FAILURE, error }
    }
}

function updateScheduleReturn(value) {
    return dispatch => {
        dispatch(request(value))
    }
    function request(value) {
        return { type: multipleTripConstants.UPDATE_SCHEDULE_RETURN_PROCESS, value }
    }
}
// https://dev-api.transportflexible.cat/services/v1/trips/customerCanPerformTrip?customerId=663ca4d8190a2db73ee82b0f&pickupStartTime=2024-07-26T10:15:00.000Z

function initScheduleReturnTrip(trip, newTime, pushToken, customerDateOfBirth, isRequestByDropOff) {
    return dispatch => {
        const isReturn = true
        const outboundTripId = trip.tripId
        const isMultipleRequest = true

        const updateRequestTripForm = factory.createUpdateRequestForm(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff)
        const body = factory.createGetTripServices(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff)

        dispatch(tripActions.getTripServices(body, trip.customerId, false, false))
        dispatch(request(updateRequestTripForm, trip.tripId))

        function request(object, outboundTripId) {
            return { type: tripConstants.SET_REQUEST_TRIP_INFO, object, outboundTripId }
        }
    }
}

function acceptTripReturn(trip, selectedTrips) {
    return dispatch => {
        const scheduleOriginalReturn = moment(trip.schedulePickUpTime)
        const tripDays = selectedTrips.map(item => {
            return moment(item.schedulePickUpTime).set({ hours: scheduleOriginalReturn.hours(), minutes: scheduleOriginalReturn.minutes() }).toISOString()
        })
        const outboundTripsId = selectedTrips.map(item => item.id)
        const body = {
            tripId: trip.tripId,
            tripDays,
            outboundTrips: outboundTripsId,
            lang: i18next.language
        }
        dispatch(processMultipleTripReturn(body))
    }
}