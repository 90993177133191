export const tripConstants = {
    ADD_TRIP_REQUEST: 'ADD_TRIP_REQUEST',
    ADD_TRIP_SUCCESS: 'ADD_TRIP_SUCCESS',
    ADD_TRIP_FAILURE: 'ADD_TRIP_FAILURE',

    GET_TRIPS_CUSTOMER_REQUEST: 'GET_TRIPS_CUSTOMER_REQUEST',
    GET_TRIPS_CUSTOMER_SUCCESS: 'GET_TRIPS_CUSTOMER_SUCCESS',
    GET_TRIPS_CUSTOMER_FAILURE: 'GET_TRIPS_CUSTOMER_FAILURE',

    GET_SERVICES_FROM_TRIP_REQUEST: 'GET_SERVICES_FROM_TRIP_REQUESTS',
    GET_SERVICES_FROM_TRIP_SUCCESS: 'GET_SERVICES_FROM_TRIP_SUCCESS',
    GET_SERVICES_FROM_TRIP_FAILURE: 'GET_SERVICES_FROM_TRIP_FAILURE',

    SET_TRIP_REQUEST: 'SET_TRIP_REQUEST',
    SET_TRIP_SUCCESS: 'SET_TRIP_SUCCESS',
    SET_TRIP_FAILURE: 'SET_TRIP_FAILURE',

    REJECT_TRIP_REQUEST: 'TRIP_CANCEL_REQUEST',
    REJECT_TRIP_SUCCESS: 'TRIP_CANCEL_SUCCESS',
    REJECT_TRIP_FAILURE: 'TRIP_CANCEL_FAILURE',

    SET_TRIP_CANCEL_BY_USER_REQUEST: 'SET_TRIP_CANCEL_BY_USER_REQUEST',
    SET_TRIP_CANCEL_BY_USER_SUCCESS: 'SET_TRIP_CANCEL_BY_USER_SUCCESS',
    SET_TRIP_CANCEL_BY_USER_FAILURE: 'SET_TRIP_CANCEL_BY_USER_FAILURE',

    SET_TRIP_ACCEPTED_REQUEST: 'SET_TRIP_ACCEPTED_REQUEST',
    SET_TRIP_ACCEPTED_SUCCESS: 'SET_TRIP_ACCEPTED_SUCCESS',
    SET_TRIP_ACCEPTED_FAILURE: 'SET_TRIP_ACCEPTED_FAILURE',

    SET_TRIP_REJECTED_BY_USER_REQUEST: 'SET_TRIP_REJECTED_BY_USER_REQUEST',
    SET_TRIP_REJECTED_BY_USER_SUCCESS: 'SET_TRIP_REJECTED_BY_USER_SUCCESS',
    SET_TRIP_REJECTED_BY_USER_FAILURE: 'SET_TRIP_REJECTED_BY_USER_FAILURE',

    GET_TRIP_DETAILS_REQUEST: 'GET_TRIP_DETAILS_REQUEST',
    GET_TRIP_DETAILS_SUCCESS: 'GET_TRIP_DETAILS_SUCCESS',
    GET_TRIP_DETAILS_FAILURE: 'GET_TRIP_DETAILS_FAILURE',

    SELECT_SERVICE_TRIP_OPEN: 'SELECT_SERVICE_TRIP_OPEN',
    SELECT_SERVICE_TRIP_CLOSE: 'SELECT_SERVICE_TRIP_CLOSE',

    SET_ORIGIN_STOPS_LIST_SUCCESS: 'SET_ORIGIN_STOPS_SUCCESS',
    SET_DESTINATION_STOPS_LIST_SUCCESS: 'SET_DESTINATION_STOPS_SUCCESS',

    CLOSE_MODAL_REQUEST_TRIP: 'CLOSE_MODAL_REQUEST_TRIP',
    OPEN_MODAL_REQUEST_TRIP: 'OPEN_MODAL_REQUEST_TRIP',

    SET_SELECTED_MY_CUSTOM_ADDRESS_SUCCESS: 'SET_SELECTED_MY_CUSTOM_ADDRESS_SUCCESS',
    SET_SELECTED_STOP_ORIGIN_SUCCESS: 'SET_SELECTED_STOP_ORIGIN_SUCCESS',
    SET_SELECTED_STOP_DESTINATION_SUCCESS: 'SET_SELECTED_STOP_DESTINATION_SUCCESS',

    CLEAR_SELECTED_STOP_ORIGIN_SUCCESS: 'CLEAR_SELECTED_STOP_ORIGIN_SUCCESS',

    RESET_DATA_TRIP_REDUCER_SUCCESS: 'RESET_DATA_TRIP_REDUCER_SUCCESS',

    SET_REPEAT_TRIP: 'TRIP_SET_REPEAT_TRIP',

    CLEAN_REPEAT_TRIP: 'TRIP_CLEAN_REPEAT_TRIP',

    CLEAN_TRIP: 'TRIP_CLEAN_TRIP',

    SET_MARKER: 'TRIP_SET_MARKER',
    SET_TRIP: 'TRIP_SET_TRIP',
    CLEAR_MARKER: 'TRIP_CLEAR_MARKER',
    CLEAR_ALL_MARKER: 'TRIP_CLEAR_ALL_MARKER',

    SET_MODIFY_TRIP_DATA_SUCCESS: 'SET_MODIFY_TRIP_DATA_SUCCESS',

    MODIFY_TRIP_REQUEST: 'MODIFY_TRIP_REQUEST',
    MODIFY_TRIP_SUCCESS: 'MODIFY_TRIP_SUCCESS',
    MODIFY_TRIP_FAILURE: 'MODIFY_TRIP_FAILURE',

    RESET_MODIFY_DATA_SUCCESS: 'RESET_MODIFY_DATA_SUCCESS',

    ACCEPT_MODIFY_TRIP_REQUEST: 'ACCEPT_MODIFY_TRIP_REQUEST',
    ACCEPT_MODIFY_TRIP_SUCCESS: 'ACCEPT_MODIFY_TRIP_SUCCESS',
    ACCEPT_MODIFY_TRIP_FAILURE: 'ACCEPT_MODIFY_TRIP_FAILURE',

    GET_WEEK_DAYS_REQUEST: 'SERVICES_GET_WEEK_DAYS_REQUEST',
    GET_WEEK_DAYS_SUCCESS: 'SERVICES_GET_WEEK_DAYS_SUCCESS',
    GET_WEEK_DAYS_FAILURE: 'SERVICES_GET_WEEK_DAYS_FAILURE',

    OPEN_MODAL_SHOW_EXPEDITIONS: 'EXPEDITIONS_OPEN_MODAL',
    CLOSE_MODAL_SHOW_EXPEDITIONS: 'EXPEDITIONS_CLOSE_MODAL',

    GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_REQUEST: 'SERVICE_GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_REQUEST',
    GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_REQUEST: 'SERVICE_GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_REQUEST',
    GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_SUCCESS: 'SERVICE_GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_SUCCESS',

    GET_POSSIBLE_DESTINATIONS_REG_LINE_REQUEST: 'GET_POSSIBLE_DESTINATIONS_REG_LINE_REQUEST',
    GET_POSSIBLE_DESTINATIONS_REG_LINE_SUCCESS: 'GET_POSSIBLE_DESTINATIONS_REG_LINE_SUCCESS',
    GET_POSSIBLE_DESTINATIONS_REG_LINE_FAILURE: 'GET_POSSIBLE_DESTINATIONS_REG_LINE_FAILURE',

    GET_SCHEDULED_PICKUP_REG_LINE_REQUEST: 'GET_SCHEDULED_PICKUP_REG_LINE_REQUEST',
    GET_SCHEDULED_PICKUP_REG_LINE_SUCCESS: 'GET_SCHEDULED_PICKUP_REG_LINE_SUCCESS',
    GET_SCHEDULED_PICKUP_REG_LINE_FAILURE: 'GET_SCHEDULED_PICKUP_REG_LINE_FAILURE',

    CLOSE_MODAL_SELECT_SCHEDULE_PICKUP: 'CLOSE_MODAL_SELECT_SCHEDULE_PICKUP',

    GET_TRIPS_TYPES_REQUEST: 'GET_TRIPS_TYPES_REQUEST',
    GET_TRIPS_TYPES_SUCCESS: 'GET_TRIPS_TYPES_SUCCESS',
    GET_TRIPS_TYPES_FAILURE: 'GET_TRIPS_TYPES_FAILURE',

    GETBYID_REQUEST: 'TRIP_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'TRIP_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'TRIP_GETBYID_FAILURE',

    SET_REQUEST_TRIP_INFO: 'SET_REQUEST_TRIP_INFO_TO_SCHEDULE_RETURN',
    CLOSE_RETURN_TRIP_MODAL: 'CLOSE_RETURN_TRIP_MODAL',
};