import { Box, Button, Checkbox, Grid, IconButton, MenuItem, Paper, TextField, Tooltip, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import Spinner from '../../shared/spinner';
import MultipleTrips from './multipleTrip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import ReplayIcon from '@material-ui/icons/Replay';
import moment from "moment";
import i18next from 'i18next';
import { Alert, AlertTitle } from '@material-ui/lab';
import { SERVICES_TYPE } from '../../../constants/types';
import ResumeTripPage from '../resumeTrip/page'
import { useSelectTrips } from './hooks/selectTrips.hook';
import { isMultipleTripOutbound, outboundHasReturn } from '../tools';
import { ArrowForward } from './icons/arrowForward';
import { ArrowBack } from './icons/arrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { tripActions } from '../../../redux/actions/trip_actions';
import { multipleTripsActions } from '../../../redux/actions/multipleTrips_action';
import MapView from '../../shared/map'
import { useTranslation } from 'react-i18next';

export const stylesMultipleTrip = makeStyles((theme) => ({
    container: {
        paddingTop: 30,
        paddingBottom: 75
    },
    containerSelectRequestType: {
        paddingTop: 40,
        paddingLeft: 20
    },
    colorPrimary: {
        color: theme.palette.primary.main,
        paddingTop: 5
    },
    center: {
        textAlign: 'center',
        padding: 10,
    },
    defaultPadding: {
        padding: theme.spacing(2)
    },
    divContainerTrip: {
        display: 'flex',
        cursor: 'pointer'
    },
    border: {
        borderBottom: '1px solid #E0E0E0',
        borderTop: '1px solid #E0E0E0'
    },
    tripProperty: {
        padding: 8,
        fontSize: '13.5px'
    },
    bold: {
        fontWeight: 'bold'
    },
    width100: {
        width: '100%'
    },
    errorContainer: {
        width: '100%',
        fontSize: theme.typography.fontSize,
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    tripSelected: {
        borderTop: `1px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        borderLeft: `8px solid ${theme.palette.primary.main}`
    }
}))


export const TripProperty = ({ children, ...props }) => {
    const classes = stylesMultipleTrip()
    return (
        <div className={classes.tripProperty}>
            <div className={props.isBold ? classes.bold : ''} >
                {children}
            </div>
        </div >
    )
}

const ResumeTripWrappedMemo = (props) => {
    const classes = stylesMultipleTrip()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { multipleTripReducer, tripReducer } = props
    const [bounds, setBounds] = React.useState(null)
    const areasReducer = useSelector((state) => state.areasReducer)

    const setStops = useCallback((stops) => {
        dispatch(tripActions.setStops(stops))
    }, [dispatch])

    const [markers, setMarkers] = React.useState([])
    return (

        <Grid container spacing={2}>
            <Grid item md={multipleTripReducer.executeMultipleTrips ? 8 : 12} className={classes.defaultPadding}>
                <Paper elevation={1}>
                    <ResumeTripPage {...props} />
                    <Grid item md={12}>
                        <div style={{ position: 'relative', width: '100%', height: '80vh', zIndex: 0 }}>
                            <MapView
                                t={t}
                                google={props.google}
                                tripReducer={tripReducer}
                                areasReducer={areasReducer}
                                setStops={setStops}
                                markers={markers}
                                setMarkers={setMarkers}
                                bounds={bounds}
                                setBounds={setBounds}
                                {...props}
                            />
                        </div>
                    </Grid>
                </Paper>
            </Grid>
            {
                multipleTripReducer.executeMultipleTrips && (
                    <Grid item md={4} className={classes.defaultPadding}>
                        <Paper elevation={1}>
                            <MultipleTrips
                                listOfTrips={multipleTripReducer.results}
                                {...props}
                            />
                        </Paper>
                    </Grid>
                )
            }
        </Grid>
    )

}

export const ResumeTripWrapped = React.memo(
    ResumeTripWrappedMemo,
    (prevProps, nextProps) => prevProps.tripReducer === nextProps.tripReducer && prevProps.multipleTripReducer.results === nextProps.multipleTripReducer.results
        && prevProps.multipleTripReducer.executeMultipleTrips === nextProps.multipleTripReducer.executeMultipleTrips
);


export const GridCenter = ({ children }) => {
    const classes = stylesMultipleTrip()
    return (
        <Grid item md={5} xl={4} className={`${classes.center}`}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={60}
            >
                {children}
            </Box>
        </Grid>
    )
}

const ListOfTripsPlannedComponentC = ({ handleMouseOver, handleCancelTrip, handleRetryTrip, serviceType, handleScheduleReturn }) => {
    const classes = stylesMultipleTrip()
    const listOfTrips = useSelector((state) => state.multipleTripReducer.results)

    const areInReturnProcess = useSelector((state) => state.multipleTripReducer.areInReturnProcess)

    const disableCheck = useCallback((trip) => {
        if (trip.tripResponse == null || trip.hasErrors)
            return true
        return moment(trip.tripResponse?.schedulePickUpTime).isBefore(moment()) || areInReturnProcess || trip.tripResponse?.isReturn
    }, [areInReturnProcess])

    const { selectedTrips, handleChangeSelectTrip, canScheduleReturn, selectAll, selectedAll, tripToPreview, changeTripSelectedToPreview, canScheduleSomeTrip } = useSelectTrips({ disableCheck })
    const dispatch = useDispatch()

    const handleClickTrip = useCallback((e, trip) => {
        if (e.target.type !== 'DIV' && trip.tripResponse != null && !areInReturnProcess)
            changeTripSelectedToPreview(trip.tripResponse)
    }, [areInReturnProcess, changeTripSelectedToPreview])

    const handleOnClickButtonSchedule = useCallback(() => {
        if (!areInReturnProcess)
            handleScheduleReturn()
        else {
            dispatch(tripActions.setTrip(listOfTrips.find(item => item.tripResponse).tripResponse))
            dispatch(multipleTripsActions.updateScheduleReturn(false))
        }
    }, [areInReturnProcess, dispatch, handleScheduleReturn, listOfTrips])

    const checkPrintIcon = useCallback((trip) => {
        if (trip.tripResponse == null)
            return false
        return outboundHasReturn(trip.tripResponse, listOfTrips) || trip.tripResponse.isReturn
    }, [listOfTrips])


    return (
        <>
            <Grid container direction="column" alignItems="center" style={{ justifyContent: "center" }} spacing={2}>
                <h2>{i18next.t('form.multipleBook.list.title')}</h2>
                {canScheduleSomeTrip &&
                    <Button variant="contained" color="primary" disabled={!canScheduleReturn} onClick={handleOnClickButtonSchedule}>
                        {!areInReturnProcess ? i18next.t('form.resumeService.scheduleReturnTripButton') : i18next.t('form.requestTripForm.buttonCancel')}
                    </Button>
                }
            </Grid>
            <Grid item md={12} style={{ display: 'flex', marginTop: 15 }}>
                <Grid item xl={3} md={2} className={`${classes.center}`}>
                    {canScheduleSomeTrip &&
                        <Checkbox checked={selectedAll} onChange={selectAll} color='primary' />
                    }
                </Grid>
                <GridCenter>{i18next.t('form.multipleBook.date')}</GridCenter>
                <GridCenter>{i18next.t('form.multipleBook.status')}</GridCenter>
            </Grid>
            {
                listOfTrips.map(trip => {
                    return (
                        <Grid key={trip.tripId} item md={12} sm={12} className={`${classes.divContainerTrip} ${classes.border} ${tripToPreview?.tripId == trip.tripId ? `${classes.tripSelected}` : ''}`} onClick={(event) => handleClickTrip(event, trip)}  >
                            <Grid item xl={3} md={2} className={`${classes.center}`}>
                                {!outboundHasReturn(trip.tripResponse, listOfTrips) && !trip.tripResponse?.isReturn &&
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        minHeight={60}
                                    >
                                        <Checkbox
                                            disabled={disableCheck(trip)}
                                            checked={selectedTrips.find(item => item.id == trip.tripId)?.checked}
                                            onChange={(event) => handleChangeSelectTrip(event, trip.tripResponse)}
                                            color='primary' />
                                    </Box>
                                }
                            </Grid>
                            <GridCenter key={trip.tripId + '_date'}>
                                <Grid item className={classes.colorPrimary}>
                                    {
                                        checkPrintIcon(trip) && (isMultipleTripOutbound(trip.tripResponse) ? ArrowForward() : ArrowBack())}
                                </Grid>
                                <Grid item style={{ padding: 13 }}>
                                    {moment(trip.hasErrors ? trip.requestPickUpTime : trip.tripResponse.schedulePickUpTime).format("DD/MM/YYYY HH:mm")}
                                </Grid>
                            </GridCenter>
                            <GridCenter key={trip.tripId + '_detail'}>
                                {
                                    trip.hasErrors ?
                                        <>
                                            <Tooltip title={i18next.t('form.multipleBook.showErrorTooltip')}>
                                                <IconButton onClick={() => handleMouseOver(trip)}>
                                                    <ErrorIcon color="error" />
                                                </IconButton>
                                            </Tooltip>
                                            {
                                                // prevent retry when we dont't have options to choose and the service is regularWithSchedule
                                                !(SERVICES_TYPE.regularWithSchedule === serviceType && trip.pickupHours?.length === 0) && (
                                                    <Tooltip title={i18next.t('form.multipleBook.retryTripTooltip')}>
                                                        <IconButton onClick={() => handleRetryTrip(trip)}>
                                                            <ReplayIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                        </>
                                        :
                                        <>
                                            <Tooltip title={i18next.t('form.multipleBook.tripAcceptedTooltip')}>
                                                <IconButton>
                                                    <CheckCircleIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={i18next.t('form.multipleBook.delete')}>
                                                <span>
                                                    <IconButton disabled={(moment(trip.tripResponse?.schedulePickUpTime).isBefore(moment()) || areInReturnProcess) ?? false}>
                                                        <CancelIcon onClick={() => handleCancelTrip(trip)} />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </>
                                }
                            </GridCenter>
                        </Grid>
                    )
                })
            }
        </>
    )
}

export const ShowErrorComponent = ({ errors }) => {
    const classes = stylesMultipleTrip()
    return (
        <div className={classes.errorContainer}>
            <Alert severity='error'>
                <AlertTitle><strong>{i18next.t('form.multipleBook.errorsOfTrip')}</strong></AlertTitle>
                <ul>
                    {
                        errors.map(item => <li key={item}>
                            {item?.detail}
                            {(item?.object?.nextAvailableTime)
                                && (
                                    `. ${i18next.t('services.trips.nextTimeAvailable')} ${moment(item?.object?.nextAvailableTime).format('HH:mm')}`
                                )
                            }
                        </li>)
                    }
                </ul>
            </Alert>
        </div>
    )
}

export const ListOfTripsPlannedComponent = React.memo(ListOfTripsPlannedComponentC, (prevProps, nextProps) => prevProps.listOfTrips == nextProps.listOfTrips)

export const withListOfTrips = (Component) => {
    const classes = stylesMultipleTrip()
    return function (props) {
        if (props.listOfTrips?.length == 0)
            return (
                <Grid item md={12} className={classes.center}>
                    {i18next.t('form.multipleBook.emptyTrips')}
                </Grid>
            )
        return <Component {...props} />
    }
}

export const ErrorsMultipleRequestTripComponent = ({ errors }) => {
    const classes = stylesMultipleTrip()
    return (
        <div className={classes.errorContainer}>
            <Alert severity='error'>
                <AlertTitle><strong>{i18next.t('form.multipleBook.errorsOfTrip')}</strong></AlertTitle>
                <ul>
                    {
                        errors.map(item => <li key={item}>
                            {item?.detail}
                            {(item?.object?.nextAvailableTime)
                                && (
                                    `. ${i18next.t('services.trips.nextTimeAvailable')} ${moment(item?.object?.nextAvailableTime).format('HH:mm')}`
                                )
                            }
                        </li>)
                    }
                </ul>
            </Alert>
        </div>
    )
}

export const SelectPosibleScheduleComponent = ({ value, handleClickOption, expeditions, pending }) => {
    return (
        <div style={{ width: '100%', alignItems: 'center' }} >
            <TextField
                fullWidth
                value={value}
                variant="outlined"
                select
                label={i18next.t('form.modalSchedule.availableTimes')}
                onChange={(event) => handleClickOption(event.target.value)}
            >
                {expeditions?.map(option =>
                    <MenuItem
                        value={option.hour}
                        key={option.hour}
                    >
                        {option.hour}
                    </MenuItem>
                )}
            </TextField>
            <Spinner
                loading={pending}
            />
        </div>
    )
}
