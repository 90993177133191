
export const multipleTripConstants = {

    PROCCESS_MULTIPLE_TRIP_REQUEST: 'PROCCESS_MULTIPLE_TRIP_REQUEST',
    PROCCESS_MULTIPLE_TRIP_SUCCESS: 'PROCCESS_MULTIPLE_TRIP_SUCCESS',
    PROCCESS_MULTIPLE_TRIP_FAILURE: 'PROCCESS_MULTIPLE_TRIP_FAILURE',

    PROCCESS_MULTIPLE_TRIP_RETURN_REQUEST: 'PROCCESS_MULTIPLE_TRIP_RETURN_REQUEST',
    PROCCESS_MULTIPLE_TRIP_RETURN_SUCCESS: 'PROCCESS_MULTIPLE_TRIP_RETURN_SUCCESS',
    PROCCESS_MULTIPLE_TRIP_RETURN_FAILURE: 'PROCCESS_MULTIPLE_TRIP_RETURN_FAILURE',

    PROCCESS_MULTIPLE_TRIP_RETRY_REQUEST: 'PROCCESS_MULTIPLE_TRIP_RETRY:REQUEST',
    PROCCESS_MULTIPLE_TRIP_RETRY_SUCCESS: 'PROCCESS_MULTIPLE_TRIP_RETRY:SUCCESS',
    PROCCESS_MULTIPLE_TRIP_RETRY_FAILURE: 'PROCCESS_MULTIPLE_TRIP_RETRY:FAILURE',

    CANCEL_TRIP_REQUEST: 'CANCEL_TRIP_PLANNED_REQUEST',
    CANCEL_TRIP_FAILURE: 'CANCEL_TRIP_PLANNED_FAILURE',

    OPEN_MODAL_SELECT_FRECUENCY: 'OPEN_MODAL_SELECT_FRECUENCY',

    GET_TRIPS_MULTIPLE_REQUEST: 'GET_TRIPS_MULTIPLE_REQUEST',
    GET_TRIPS_MULTIPLE_SUCCESS: 'GET_TRIPS_MULTIPLE_SUCCESS',
    GET_TRIPS_MULTIPLE_FAILURE: 'GET_TRIPS_MULTIPLE_FAILURE',

    SHOW_MODAL_DETAILS: 'SHOW_MODAL_DETAILS',
    UPDATE_LIST_TRIPS: 'UPDATE_LIST_TRIPS',
    SET_TRIP_ID_PLAN: 'SET_TRIP_ID_PLAN',

    UPDATE_SELECTED_TRIPS: 'UPDATE_SELECTED_TRIPS',
    UPDATE_SCHEDULE_RETURN_PROCESS: 'UPDATE_SCHEDULE_RETURN_PROCESS',

    UPDATE_TRIPS_TO_LIST_EXIST: "UPDATE_TRIPS_TO_LIST_EXIST",
    SET_PREVIEW_TRIP: 'SET_PREVIEW_TRIP',
}