import moment from "moment"

export const getOriginRequestTrip = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.pickUpLocation.desc.description
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.pickUpLocation.address
    }

    return ''
}

export const getDestinationRequestTrip = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.dropOffLocation.desc.description
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.dropOffLocation.address
    }

    return ''
}

export const getPickUpTimeRequestTrip = (tripReducer) => {

    if (tripReducer.repeatTrip && tripReducer.repeatDataObject != null) {
        return new Date(tripReducer.repeatDataObject.fistAvailableDate)
    }

    if (tripReducer.modifyTripData != null) {
        return new Date(tripReducer.modifyTripData.requestPickUpStartTime)
    }

    return null
}

export const getPassengersRequestTrip = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.numPassengers
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.numPassengers
    }

    return 1
}

export const getLuggageRequestTrip = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.luggage
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.luggage
    }

    return 0
}

export const getWheelChairRequestTrip = (tripReducer, customerReducer) => {
    if (tripReducer.repeatDataObject == null) {
        return customerReducer.currentUser.hasWheelChair
    }

    const { hasWheelChair } = tripReducer.repeatDataObject
    return hasWheelChair
}

export const getIsOnRelatedCustomerBehalf = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.isOnRelatedCustomerBehalf
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.isOnRelatedCustomerBehalf
    }

    return false
}

export const getRelatedCustomer = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.relatedCustomer
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.relatedCustomer
    }

    return null
}

export const isMultipleTripOutbound = (trip) => {
    if (trip == null) return false
    return (trip.isMultipleRequest && !trip.isReturn)
}

export const isMultipleTripReturn = (trip) => {
    if (trip == null) return false
    return (trip.isMultipleRequest && trip.isReturn)
}


export const outboundHasReturn = (trip, listOfTrips) => {
    if (trip == null) return false
    return listOfTrips.filter(item => item.tripResponse != null).some(
        item => moment(item.tripResponse.schedulePickUpTime).isSame(moment(trip.schedulePickUpTime), 'day') && item.tripResponse.isReturn && item.tripId != trip.tripId)
}

export const changeTimeToTrip = (trip, newTime) => {
    let originalSchedule = moment(trip?.requestPickUpTime)
    let newTimeSplitted = null
    if (typeof newTime === "string")
        newTimeSplitted = newTime.split(":")
    else
        newTimeSplitted = moment(newTime).format("HH:mm").split(":")

    originalSchedule.set({ hour: parseInt(newTimeSplitted[0]), minute: parseInt(newTimeSplitted[1]) })
    return originalSchedule
}

export const getIsDropOffRequest = (tripReducer) => {
    return tripReducer.isRequestByDropOff ?? false
}

export const isReturn = (tripReducer) => {
    if (tripReducer.repeatDataObject == null)
        return false
    return tripReducer.repeatDataObject.isReturn
}

export const getOriginalId = (tripReducer) => {
    if (tripReducer.repeatDataObject == null)
        return null
    return tripReducer.repeatDataObject.originalTripId
}

export const getOutboundTripId = (tripReducer) => {
    if (tripReducer.repeatDataObject == null)
        return null
    return tripReducer.repeatDataObject.outboundTripId
}